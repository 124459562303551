import React from "react";
import clsx from "clsx";

import { Container, Text, ButtonAlt } from "@atoms";

const FeatureGrid = ({ bgColor, heading, features, className: _className }) => {
  return (
    <section
      className={clsx(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-mint": bgColor === "mint",
        },
        _className
      )}
    >
      <Container variant="xl">
        {heading && (
          <div className="mx-auto w-full max-w-2xl">
            <Text variant="h2" className="mb-8 text-center md:mb-16">
              {heading}
            </Text>
          </div>
        )}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {features?.map(feature => {
            const { uid, heading: title, descriptor, link } = feature;
            return (
              <div
                key={uid}
                className={clsx(
                  "flex h-full flex-col items-start border-l-2 pl-2",
                  {
                    "border-mint": bgColor === "midnight",
                    "border-carnation": bgColor === "white",
                  }
                )}
              >
                <Text
                  variant="h4sans"
                  className={clsx("font-bold", {
                    "text-mint": bgColor === "midnight",
                    "text-carnation": bgColor === "white",
                  })}
                >
                  {title}
                </Text>
                <Text
                  variant="body"
                  className={clsx("my-4", {
                    "text-white": bgColor === "midnight",
                  })}
                >
                  {descriptor}
                </Text>
                {link?.url && (
                  <ButtonAlt to={link.url} className="mt-auto">
                    {link.text || "Learn More"}
                  </ButtonAlt>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

FeatureGrid.defaultProps = {
  bgColor: "midnight",
  paddingBottom: false,
  paddingTop: false,
};

export default FeatureGrid;
